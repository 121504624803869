/* Remove blue fram around map pin point when focused */
#map_graphics_layer path {
    stroke: none !important;
}

#map {
    /* Overwrite of classes, avoid as much as possible
    Prefer using custom class defined below */
    .esri-view-surface:focus::after {
        display: none;
    }

    /* Custom classes for Arcgis map */
    .app-esri- {
        &attribution {
            left: auto;
            font-family: "Roboto", sans-serif;
        }

        &zoom * {
            font-weight: 900;
        }
    }

    .esri-features__container {
        max-height: 50vh;
    }

    .esri-ui-bottom-left {
        bottom: 20px;
    }
}

/* Overidde esri.css */
.esriSimpleSliderTL {
    top: 40vh !important;
    left: auto !important;
    right: 16px !important;
    background-color: transparent !important;
    border: none !important;
}

.esriSimpleSliderIncrementButton,
.esriSimpleSliderDecrementButton {
    height: 40px !important;
    width: 40px !important;
    display: flex !important;
    line-height: 24px !important;
    box-shadow: $box-shadow;
    border-radius: 5px !important;
    background-color: $accent !important;
    color: $white !important;
    margin-bottom: 16px;
}

.esriSimpleSliderIncrementButton {
    border: none !important;
}

.esriSimpleSliderIncrementButton span,
.esriSimpleSliderDecrementButton span {
    margin: auto !important;
}

.esriSimpleSliderDisabledButton {
    opacity: 0.5 !important;
}

.esriPopup {
    font-family: Inter, "Roboto", sans-serif !important;
}

.esriPopupWrapper {
    margin: 5px;
    box-shadow: none !important;
}

/* Map items cursor on hover */
#assetClusterFeatureLayer_layer circle,
text {
    cursor: pointer;
    font-family: Inter, "Roboto", sans-serif !important;
}

#beaconClusterFeatureLayer_layer circle,
text {
    cursor: pointer;
    font-family: Inter, "Roboto", sans-serif !important;
}

#anchorClusterFeatureLayer_layer circle,
text {
    cursor: pointer;
    font-family: Inter, "Roboto", sans-serif !important;
}

#historyAssetClusterFeatureLayer_layer circle,
text {
    cursor: pointer;
    font-family: Inter, "Roboto", sans-serif !important;
}

#assetGraphicsLayer_layer path {
    cursor: pointer;
}

#beaconGraphicsLayer_layer path {
    cursor: pointer;
}

#anchorGraphicsLayer_layer path {
    cursor: pointer;
}

#historyGraphicsLayer_layer path {
    cursor: pointer;
}

#map_graphics_layer path {
    cursor: pointer;
}

.no-data-message {
    margin-top: 8px;
    color: $warn;
}
